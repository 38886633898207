<template>
	<div>
		<!-- Domain List -->
		<vx-card>
			<div slot="no-body">
				<div class="vx-card__header">
					<div class="vx-card__title">
						<h4>Domain List : <span class="text-grey">{{ paramId }}</span></h4>
						<small>suspension</small>
					</div>
					<template v-if="completionPercent">
						<template v-if="completionPercent<100">
							<div class="badge badge--danger inline-block">{{ completionPercent }}%</div>
						</template>
						<template v-else>
							<div class="badge badge--success inline-block">{{ completionPercent }}%</div>
						</template>
					</template>
				</div>
				<div class="p-6 pt-0">
					<ag-grid-vue
						:gridOptions="gridOptions"
						class="ag-theme-material w-100 mb-4 ag-grid-table ag-grid-table--transparent"
						:columnDefs="columnDefs"
						:defaultColDef="defaultColDef"
						:rowData="tableData"
						rowSelection="multiple"
						:enableRangeSelection="true"
						:suppressCopyRowsToClipboard="true"
						colResizeDefault="shift"
						:suppressDragLeaveHidesColumns="true"
						:animateRows="false"
						:floatingFilter="true"
						:pagination="true"
						:paginationPageSize="paginationPageSize"
						:suppressPaginationPanel="true"
						:suppressRowClickSelection="true"
						:rowClassRules="rowClassRules"
						:statusBar="statusBar"
						:sideBar="sideBar"
						:rowHeight="38"
						:getContextMenuItems="getContextMenuItems"
						@column-resized="onColumnResized">
					</ag-grid-vue>
					<div class="vx-row">
						<div class="vx-col w-full">
							<vs-pagination
							:total="totalPages"
							:max="maxPageNumbers"
							v-model="currentPage" />
						</div>
					</div>
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
		},
		props: {
			paramId: {
				type: String | Number,
				default: null
			},
			paramPercent: {
				type: Number,
				default: null
			},
		},
		data() {
			return {
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filterParams: {
						suppressAndOrCondition: true
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				columnDefs: [
				{
					headerName: 'Domain',
					field: 'domain',
					minWidth: 275,
					width: 275,
					filter: "agTextColumnFilter",
					pinned: 'left',
					cellRenderer: (data) => {
						if(data.value) {
							return `<a href="/search?domain_name=${data.value}" target="_blank">${data.value}</a>`
						}
						else {
							return '';
						}
					},
				},
				{
					headerName: 'Domain ID',
					field: 'domain_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'TLD',
					field: 'tld',
					filter: "agSetColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep',
						values: ['site','website','store','online','tech','press','host','fun','space','uno','pw','in.net'],
						suppressMiniFilter: true,
					},
					minWidth: 100,
					width: 100,
				},
				{
					headerName: 'Source ID',
					field: 'source_id',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Source',
					field: 'source',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Category ID',
					field: 'category_id',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Category',
					field: 'category',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Reviewed',
					field: 'reviewed_at',
					filter: "agTextColumnFilter",
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return `<div class="badge badge--success inline">Processed</div>`
						}
						else {
							return `<div class="badge badge--warning inline">Queued</div>`
						}
					},
				},
				{
					headerName: 'Note',
					field: 'note',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Blocked Status',
					field: 'blocked_status',
					filter: "agTextColumnFilter",
					minWidth: 150,
					cellRenderer: (data) => {
						if(data.value) {
							return data.value
						}
						else {
							return '-'
						}
					},
				},
				{
					headerName: 'Issue',
					field: 'blocked_issue',
					filter: "agTextColumnFilter",
					minWidth: 275,
					cellRenderer: (data) => {
						if(data.value) {
							return data.value
						}
						else {
							return '-'
						}
					},
				},
				{
					headerName: 'Not Actionable Status',
					field: 'not_actionable_status',
					filter: "agTextColumnFilter",
					minWidth: 200,
					cellRenderer: (data) => {
						if(data.value) {
							return data.value
						}
						else {
							return '-'
						}
					},
				},
				{
					headerName: 'Action Date',
					field: 'action_date',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
				},
				{
					headerName: 'Current Status',
					field: 'current_status',
					filter: "agSetColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep',
						values: ['A','SH','SHP','SHF','PD','D'],
						suppressMiniFilter: true,
					},
					cellRenderer: (data) => {
						if(data.value) {
							switch (data.value) {
								case 'A':
									return `<div class="badge badge--success inline">${data.value}</div>`
								case 'SH':
									return `<div class="badge badge--danger inline">${data.value}</div>`
								case 'SHP':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'SHF':
									return `<div class="badge badge--warning inline">${data.value}</div>`
								case 'PD':
									return `<div class="badge badge--info inline">${data.value}</div>`
								case 'D':
									return `<div class="badge badge--dark inline">${data.value}</div>`
								default:
									return `<div class="badge badge--light inline">Queued</div>`
							}
						}
						else {
							return ''
						}
					},
					minWidth: 100,
				},
				{
					headerName: 'Created At',
					field: 'created_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
					sort: 'desc',
				},
				{
					headerName: 'Updated At',
					field: 'updated_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					hide: true,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				],
				tableData: [],
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
			paramId() {
				// Table Data AXIOS Call
				this.populateTableData();
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			completionPercent() {
				return this.tableData.length ? this.tableData[0]['progress_rate'] : 0
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
		},
		methods: {
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			populateTableData() {
				let self = this;
				// Get Axios Call
				this.$axiosSecure.get('/suspension-action-review-batch-json', {
					params: {
						batch_id: self.paramId
					}
				})
				.then((response) => {
					this.tableData = response.data;

					if(this.paramPercent != 100) {
						// Refresh ActionReview
						this.$emit('refreshActionReview');
					}
				})
				.catch((error) => {
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			getContextMenuItems(params) {
				let result,
					self = this;

				if(params['column'] && params['column']['colId'] && params['column']['colId'] == 'domain') {
					result = [
						{
							name: "Resolve Domain(s)",
							action: function() {
								let rangeSelection = self.gridApi.getCellRanges(),
									value = params['value'];

								if(rangeSelection) {
									// Get selected cells & iterate through them to find domains
									rangeSelection.forEach(item => {
										let start = Math.min(item.startRow.rowIndex, item.endRow.rowIndex),
											end = Math.max(item.startRow.rowIndex, item.endRow.rowIndex),
											isRightClicked = false;
										
										for (let i = start; i <= end; i++) {
											if(self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'] == value) {
												isRightClicked = true
											}
											window.open('https://anonymto.com/?http://' + self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'], '_blank');
										}
										
										if(!isRightClicked) {
											window.open('https://anonymto.com/?http://' + value);
										}
									});
								}
								else {
									window.open('https://anonymto.com/?http://' + value);
								}
							},
						},
						'separator',
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}
				else {
					result = [
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}

				return result;
			}
		},
		beforeMount() {
			// Table Data AXIOS Call
			this.populateTableData();

			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid statusbar
			this.statusBar = {
				statusPanels: [
				{
					statusPanel: "agTotalRowCountComponent",
					align: "left"
				},
				{
					statusPanel: "agFilteredRowCountComponent",
					align: "right"
				}
				]
			};

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Resize to fit columns
			this.sizeColumnsToFit();
		}
	}
</script>
